.login {
	margin: 15px;
	height: 95vh;
	display: grid;
	place-content: center;
}

.input {
	width: 25vw;
}

.button {
	width: 100%;
	margin-top: 2vh;
}

@media only screen and (max-width: 600px) {
	.login {
		height: 100%;
	}
}
