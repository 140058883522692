.customer {
  margin: 15px;
}

.input {
  width: 100%;
  margin: 2px;
}

.TextField {
  width: 100%;
  margin: 2px;
}

.button {
  width: 100%;
  margin: 2px;
}
