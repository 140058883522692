.input {
  width: 100%;
  margin: 2px;
}

.button {
  width: 100%;
  margin: 2px;
}

.button2 {
  width: 50%;
  margin: 2px;
}

.popheader {
  background-color: #fabd64;
}

.resumen {
  display: flex;
  color: #ffff;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}
