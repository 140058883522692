.input {
	width: 100%;
	margin: 2px;
}

.button {
	width: 100%;
	margin: 2px;
}

 
.popheader { 
	background-color:#FABD64;
}



.resumen {
  display: flex;
  color: #FFFF;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}


